var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "1250px" },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "button",
                {
                  staticClass: "v-btn white",
                  attrs: { id: "openX_btn" },
                  on: {
                    click: function ($event) {
                      _vm.alert = true
                    },
                  },
                },
                [_vm._v(" ✖ ")]
              ),
              _c("v-card-title", { staticClass: "text-h4" }, [
                _vm._v("Case Edit"),
              ]),
              _c(
                "v-alert",
                { attrs: { value: _vm.alert, prominent: "", type: "warning" } },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c("v-col", { staticClass: "grow" }, [
                        _vm._v(
                          " This case has not been saved. Would you like to continue without saving? "
                        ),
                      ]),
                      _c(
                        "v-col",
                        { staticClass: "shrink" },
                        [
                          _c("v-btn", { on: { click: _vm.noSave } }, [
                            _vm._v("Exit Without Saving"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: { "max-width": "640px" },
                  model: {
                    value: _vm.dialogQtrData,
                    callback: function ($$v) {
                      _vm.dialogQtrData = $$v
                    },
                    expression: "dialogQtrData",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", [
                        _c("span", { staticClass: "text-h5" }, [
                          _vm._v("Quarterly Downtime Data (days)"),
                        ]),
                      ]),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-container",
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "12", sm: "12", md: "12" },
                                    },
                                    [
                                      _vm.showQ1
                                        ? _c("v-text-field", {
                                            attrs: {
                                              label: "Quarter 1 Downtime",
                                            },
                                            model: {
                                              value: _vm.q1,
                                              callback: function ($$v) {
                                                _vm.q1 = $$v
                                              },
                                              expression: "q1",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "12", sm: "12", md: "12" },
                                    },
                                    [
                                      _vm.showQ2
                                        ? _c("v-text-field", {
                                            attrs: {
                                              label: "Quarter 2 Downtime",
                                            },
                                            model: {
                                              value: _vm.q2,
                                              callback: function ($$v) {
                                                _vm.q2 = $$v
                                              },
                                              expression: "q2",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "12", sm: "12", md: "12" },
                                    },
                                    [
                                      _vm.showQ3
                                        ? _c("v-text-field", {
                                            attrs: {
                                              label: "Quarter 3 Downtime",
                                            },
                                            model: {
                                              value: _vm.q3,
                                              callback: function ($$v) {
                                                _vm.q3 = $$v
                                              },
                                              expression: "q3",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "12", sm: "12", md: "12" },
                                    },
                                    [
                                      _vm.showQ4
                                        ? _c("v-text-field", {
                                            attrs: {
                                              label: "Quarter 4 Downtime",
                                            },
                                            model: {
                                              value: _vm.q4,
                                              callback: function ($$v) {
                                                _vm.q4 = $$v
                                              },
                                              expression: "q4",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-card-text",
                                { staticClass: "text-h6 ml-n4" },
                                [
                                  _vm._v(
                                    " To unpause components and update component ID numbers click "
                                  ),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "underline",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openWindow(
                                            "/sites/" +
                                              _vm.siteId +
                                              "/inverters_list"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("here")]
                                  ),
                                  _vm._v(". "),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "font-weight-bold text-xs btn-danger bg-gradient-danger",
                              on: {
                                click: function ($event) {
                                  _vm.dialogQtrData = false
                                },
                              },
                            },
                            [_vm._v(" Cancel ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "font-weight-bold text-xs btn-success bg-gradient-success",
                              on: { click: _vm.saveQtrData },
                            },
                            [_vm._v(" Save ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-form",
                {
                  ref: "newCaseForm",
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c(
                    "v-container",
                    { staticStyle: { padding: "3px" } },
                    [
                      _c(
                        "v-row",
                        { staticStyle: { padding: "3px" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticStyle: { padding: "3px" },
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  items: _vm.sites,
                                  "item-text": "name",
                                  "item-value": "id",
                                  label: "Select Site",
                                  rules: [
                                    function (v) {
                                      return !!v || "Site is required"
                                    },
                                  ],
                                },
                                on: { change: _vm.siteSelect },
                                model: {
                                  value: _vm.siteId,
                                  callback: function ($$v) {
                                    _vm.siteId = $$v
                                  },
                                  expression: "siteId",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticStyle: { padding: "3px" },
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Subject",
                                  required: "",
                                  rules: [
                                    function (v) {
                                      return !!v || "Subject is required"
                                    },
                                  ],
                                },
                                model: {
                                  value: _vm.subject_edit,
                                  callback: function ($$v) {
                                    _vm.subject_edit = $$v
                                  },
                                  expression: "subject_edit",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticStyle: { padding: "3px" },
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  items: _vm.casestatus,
                                  "item-text": "name",
                                  "item-value": "id",
                                  label: "Select Status",
                                  "allow-overflow": true,
                                  rules: [
                                    function (v) {
                                      return !!v || "Status is required"
                                    },
                                  ],
                                },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.caseStatusEnter.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                  change: _vm.caseStatusChange,
                                },
                                model: {
                                  value: _vm.caseStatus,
                                  callback: function ($$v) {
                                    _vm.caseStatus = $$v
                                  },
                                  expression: "caseStatus",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticStyle: { padding: "3px" },
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: { label: "Case ID", readonly: "" },
                                model: {
                                  value: _vm.id_edit,
                                  callback: function ($$v) {
                                    _vm.id_edit = $$v
                                  },
                                  expression: "id_edit",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticStyle: { padding: "3px" },
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "close-on-content-click": false,
                                    "nudge-right": 40,
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "max-width": "290px",
                                    "min-width": "290px",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-text-field",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  label: "Date Added",
                                                  "prepend-icon": "event",
                                                  readonly: "",
                                                  value: _vm.dateAddedDisp,
                                                },
                                              },
                                              on
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.date_added_edit,
                                    callback: function ($$v) {
                                      _vm.date_added_edit = $$v
                                    },
                                    expression: "date_added_edit",
                                  },
                                },
                                [
                                  _c("v-date-picker", {
                                    attrs: { locale: "en-in", "no-title": "" },
                                    on: {
                                      input: function ($event) {
                                        _vm.dateAddedMenu = false
                                      },
                                    },
                                    model: {
                                      value: _vm.dateAddedVal,
                                      callback: function ($$v) {
                                        _vm.dateAddedVal = $$v
                                      },
                                      expression: "dateAddedVal",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticStyle: { padding: "3px" },
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: { label: "Kw affected", required: "" },
                                model: {
                                  value: _vm.kw_affected_edit,
                                  callback: function ($$v) {
                                    _vm.kw_affected_edit = $$v
                                  },
                                  expression: "kw_affected_edit",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticStyle: { padding: "3px" },
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: { label: "Downtime Days", readonly: "" },
                                model: {
                                  value: _vm.downtime_days_edit,
                                  callback: function ($$v) {
                                    _vm.downtime_days_edit = $$v
                                  },
                                  expression: "downtime_days_edit",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticStyle: { padding: "3px" },
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: { label: "Monitoring Platform" },
                                model: {
                                  value: _vm.monitoring_plat_edit,
                                  callback: function ($$v) {
                                    _vm.monitoring_plat_edit = $$v
                                  },
                                  expression: "monitoring_plat_edit",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticStyle: { padding: "3px" },
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Lost production",
                                  readonly: "",
                                },
                                model: {
                                  value: _vm.lost_production_edit,
                                  callback: function ($$v) {
                                    _vm.lost_production_edit = $$v
                                  },
                                  expression: "lost_production_edit",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticStyle: { padding: "3px" },
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  items: _vm.casecategories,
                                  "item-text": "name",
                                  "item-value": "id",
                                  "allow-overflow": true,
                                  label: "Select Category",
                                  rules: [
                                    function (v) {
                                      return !!v || "Category is required"
                                    },
                                  ],
                                },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.caseCategoryEnter.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                  change: _vm.caseCategoryChange,
                                },
                                model: {
                                  value: _vm.caseCategory,
                                  callback: function ($$v) {
                                    _vm.caseCategory = $$v
                                  },
                                  expression: "caseCategory",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticStyle: { padding: "3px" },
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  items: _vm.casepriorities,
                                  "allow-overflow": true,
                                  "item-text": "name",
                                  "item-value": "id",
                                  label: "Select Priority",
                                  rules: [
                                    function (v) {
                                      return !!v || "Priority is required"
                                    },
                                  ],
                                },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.casePriorityEnter.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                  change: _vm.casePriorityChange,
                                },
                                model: {
                                  value: _vm.casePriority,
                                  callback: function ($$v) {
                                    _vm.casePriority = $$v
                                  },
                                  expression: "casePriority",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticStyle: { padding: "3px" },
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  items: _vm.contacts,
                                  "item-text": "full_name",
                                  "item-value": "id",
                                  required: "",
                                  "allow-overflow": true,
                                  label: "Select Service Team",
                                  rules: [
                                    function (v) {
                                      return !!v || "Contact is required"
                                    },
                                  ],
                                },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.contactEnter.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                  change: _vm.contactChange,
                                },
                                model: {
                                  value: _vm.contact,
                                  callback: function ($$v) {
                                    _vm.contact = $$v
                                  },
                                  expression: "contact",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticStyle: { padding: "3px" },
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: { label: "Vendor Case #" },
                                model: {
                                  value: _vm.vendor_case_num_edit,
                                  callback: function ($$v) {
                                    _vm.vendor_case_num_edit = $$v
                                  },
                                  expression: "vendor_case_num_edit",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticStyle: { padding: "3px" },
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: { label: "Address", readonly: "" },
                                model: {
                                  value: _vm.address_line_1_edit,
                                  callback: function ($$v) {
                                    _vm.address_line_1_edit = $$v
                                  },
                                  expression: "address_line_1_edit",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticStyle: { padding: "3px" },
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: { readonly: "", label: "City" },
                                model: {
                                  value: _vm.city_edit,
                                  callback: function ($$v) {
                                    _vm.city_edit = $$v
                                  },
                                  expression: "city_edit",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("v-col", {
                            staticStyle: { padding: "3px" },
                            attrs: { cols: "12", md: "4" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticStyle: { padding: "3px" },
                              attrs: { cols: "12", md: "12" },
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.inverters,
                                  "item-text": "name",
                                  "item-value": "id",
                                  label: "Equipment Down",
                                  multiple: "",
                                  chips: "",
                                  "deletable-chips": "",
                                  "hide-selected": "",
                                },
                                on: { change: _vm.addEquip },
                                scopedSlots: _vm._u([
                                  {
                                    key: "selection",
                                    fn: function (ref) {
                                      var attrs = ref.attrs
                                      var item = ref.item
                                      return [
                                        _c(
                                          "v-chip",
                                          _vm._b(
                                            {
                                              attrs: { close: "" },
                                              on: {
                                                "click:close": function (
                                                  $event
                                                ) {
                                                  return _vm.removeEquip(item)
                                                },
                                              },
                                            },
                                            "v-chip",
                                            attrs,
                                            false
                                          ),
                                          [_vm._v(_vm._s(item.name))]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.equipment,
                                  callback: function ($$v) {
                                    _vm.equipment = $$v
                                  },
                                  expression: "equipment",
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "text-center" },
                                [
                                  _c(
                                    "v-dialog",
                                    {
                                      attrs: { persistent: "", width: "500" },
                                      model: {
                                        value: _vm.pause_dialog,
                                        callback: function ($$v) {
                                          _vm.pause_dialog = $$v
                                        },
                                        expression: "pause_dialog",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        [
                                          _c(
                                            "v-card-title",
                                            {
                                              staticClass: "text-h5 lighten-2",
                                            },
                                            [
                                              _vm._v(
                                                " Would you like to pause this component? "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-card-actions",
                                            [
                                              _c("v-spacer"),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "font-weight-bold text-xs btn-danger bg-gradient-danger",
                                                  on: { click: _vm.noPause },
                                                },
                                                [_vm._v(" No ")]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "font-weight-bold text-xs btn-success bg-gradient-success",
                                                  on: {
                                                    click: _vm.pauseComponent,
                                                  },
                                                },
                                                [_vm._v(" Yes ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pr-0", attrs: { cols: "2" } },
                            [
                              this.id_edit &&
                              this.edit_item &&
                              this.edit_item.site
                                ? _c("work-order", {
                                    attrs: {
                                      case_id: this.id_edit,
                                      subject: this.subject_edit,
                                      date: this.dateAddedVal,
                                      site_name: this.edit_item.site.name,
                                      equipment: this.equipment,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "text-left", attrs: { cols: "6" } },
                            [
                              this.id_edit != null
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "font-weight-bold text-xs btn-success bg-gradient-success",
                                      on: {
                                        click: _vm.openComponentScheduleEdit,
                                      },
                                    },
                                    [_vm._v(" Update Component Schedule ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticStyle: { padding: "3px" },
                              attrs: { cols: "12", md: "12" },
                            },
                            [
                              this.id_edit != null &&
                              this.edit_item &&
                              this.edit_item.site
                                ? _c("case-notev-2", {
                                    attrs: {
                                      vendor_num: this.vendor_case_num_edit,
                                      subject: this.subject_edit,
                                      case_id: this.id_edit,
                                      site_plans: this.edit_item.site.sitePlans,
                                      system:
                                        this.edit_item.site.monitoringSystem,
                                      nativeLink:
                                        this.edit_item.site
                                          .nativeMonitoringLink,
                                      site_id: this.siteId,
                                      site_name: this.edit_item.site.name,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mb-1" },
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "font-weight-bold text-xs btn-success bg-gradient-success",
                                  attrs: { ripple: false, elevation: 0 },
                                  on: { click: _vm.saveCasePopup },
                                },
                                [_vm._v(" Save ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "font-weight-bold text-xs btn-success bg-gradient-success ml-1",
                                  attrs: {
                                    ripple: false,
                                    elevation: 0,
                                    disabled: !(
                                      _vm.edit_item && _vm.edit_item.id
                                    ),
                                  },
                                  on: { click: _vm.saveCaseClosePopup },
                                },
                                [_vm._v(" Save And Close ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.user.claims.user_role ==
                                          "super_user" ||
                                        _vm.user.claims.user_role ==
                                          "customer_admin",
                                      expression:
                                        "\n                user.claims.user_role == 'super_user' ||\n                user.claims.user_role == 'customer_admin'\n              ",
                                    },
                                  ],
                                  staticClass:
                                    "font-weight-bold text-xs btn-default bg-gradient-danger ml-1",
                                  attrs: {
                                    disabled: !(
                                      _vm.edit_item && _vm.edit_item.id
                                    ),
                                    ripple: false,
                                    elevation: 0,
                                  },
                                  on: { click: _vm.deleteCase },
                                },
                                [_vm._v(" Delete ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("pausing-modal", {
        attrs: {
          eventToCall: _vm.eventToCall,
          show_switch: _vm.showPausingModal,
          site_id: _vm.siteId,
        },
        on: {
          close: _vm.closePausingModal,
          saveCase: _vm.saveCase,
          saveCloseCase: _vm.saveCloseCase,
          updateDataFromPauseModal: _vm.updateDataFromPauseModal,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }