var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "700px" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "mb-2",
                      staticStyle: { "margin-left": "-1%" },
                      attrs: {
                        disabled: _vm.user.claims.user_role == "customer_view",
                        color: "primary",
                        dark: "",
                      },
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v(" Work Order ")]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.orderDialog,
        callback: function ($$v) {
          _vm.orderDialog = $$v
        },
        expression: "orderDialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c("span", { staticClass: "text-h5" }, [_vm._v("Work Order")]),
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "12", md: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: { label: "Equipment access notes" },
                            model: {
                              value: _vm.accessNotes,
                              callback: function ($$v) {
                                _vm.accessNotes = $$v
                              },
                              expression: "accessNotes",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._l(_vm.equipment, function (e, index) {
                    return _c(
                      "v-row",
                      { key: index },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "6", sm: "6", md: "6" } },
                          [
                            _c("v-text-field", {
                              attrs: { label: "Equipment name" },
                              model: {
                                value: e.name,
                                callback: function ($$v) {
                                  _vm.$set(e, "name", $$v)
                                },
                                expression: "e.name",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "6", sm: "6", md: "6" } },
                          [
                            _c("v-text-field", {
                              attrs: { label: "Serial number" },
                              model: {
                                value: e.inverter_monitoring_id,
                                callback: function ($$v) {
                                  _vm.$set(e, "inverter_monitoring_id", $$v)
                                },
                                expression: "e.inverter_monitoring_id",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  _vm._l(_vm.infoFields, function (info, index) {
                    return _c(
                      "v-row",
                      { key: index },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "6", sm: "6", md: "6" } },
                          [
                            _c("v-text-field", {
                              attrs: { label: "Equipment name" },
                              model: {
                                value: info.equipmentName,
                                callback: function ($$v) {
                                  _vm.$set(info, "equipmentName", $$v)
                                },
                                expression: "info.equipmentName",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "6", sm: "6", md: "6" } },
                          [
                            _c("v-text-field", {
                              attrs: { label: "Serial number" },
                              model: {
                                value: info.serialNumber,
                                callback: function ($$v) {
                                  _vm.$set(info, "serialNumber", $$v)
                                },
                                expression: "info.serialNumber",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "black", outlined: "" },
                      on: {
                        click: function ($event) {
                          return _vm.addRow(_vm.index)
                        },
                      },
                    },
                    [_vm._v(" Add New Row ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-2",
                      attrs: {
                        color: "black",
                        outlined: "",
                        show: "infoFields.length > 1",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.removeRow(_vm.index)
                        },
                      },
                    },
                    [_vm._v(" Remove Row ")]
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bold text-xs btn-default bg-gradient-danger",
                  attrs: { ripple: false, elevation: 0 },
                  on: { click: _vm.close },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bold text-xs btn-success bg-gradient-success",
                  attrs: { ripple: false, elevation: 0 },
                  on: { click: _vm.generatePdf },
                },
                [_vm._v(" Generate Work Order PDF ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }