<template>
  <v-dialog
    v-model="show"
    max-width="950px"
    persistent
  >
    <v-card>
      <v-card-title class="text-h5">Component Schedule Helper</v-card-title>
      <v-container>
        <v-row>
          <v-col>
            <v-label for="component-type" class="mb-2">Please select a Component Type:</v-label>
            <v-select
              id="component-type"
              v-model="selectedOption"
              :items="componentTypes"
              item-text="label"
              item-value="value"
              @change="loadComponentItems"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-dialog v-model="pauseAllDialog" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-show="selectedOption=='inverter'"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="sitePause"
                  height="43"
                  class="mt-n12 mb-n12"
                >
                  Pause Selected
                </v-btn>
              </template>
              <v-card class="card-shadow border-radius-xl">
                  <div class="card-header-padding card-border-bottom">
                    <span class="font-weight-bold text-h5 text-typo mb-0">
                      Pause Until Date
                    </span>
                  </div>
                  <div class="ml-12">
                    <v-date-picker
                      v-model="pauseAllDate"
                      color="pink"
                      width="400px"
                      show-adjacent-months
                    >
                    </v-date-picker>
                  </div>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    elevation="0"
                    :ripple="false"
                    class="
                      font-weight-bold
                      btn-ls btn-primary
                      bg-gradient-primary
                      py-3
                      px-6
                    "
                    @click="pauseAllComponents"
                  >
                    Pause
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              v-model="selectedComps"
              :headers="tableHeaders"
              :items="results"
              :items-per-page="5"
              return-object
              show-select
              class="elevation-1"
            >
              <template v-slot:item.paused="{ item }">
                <!-- <v-switch
                  :disabled="(sitePause && !item.is_scheduled) || (item.type == 2 && item.is_paused && !item.is_scheduled)"
                  v-model="item.paused"
                  @change="handlePausedSwitch(item)"
                  :label="item.paused ? 'Paused' : 'Active'"
                ></v-switch> -->
                <v-chip
                  v-model="item.paused"
                  v-if="item.paused"
                  text-color="white"
                  color="pink"

                >Paused</v-chip>
                <v-chip
                  v-else
                  v-model="item.paused"
                  text-color="white"
                  color="green"
                  

                >Active</v-chip>
              </template>
              <template v-slot:item.datePopup="{ item }">
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">mdi-calendar</v-icon>
                  </template>
                  <v-date-picker
                    :disabled="sitePause"
                    v-model="item.datePopup"
                    no-title
                    :color="datePickerColor"
                    @change="updateDateText(item)"
                  ></v-date-picker>
                </v-menu>
              </template>
              <template v-slot:item.dateText="{ item }">
                <v-text-field v-model="item.datePopup" readonly></v-text-field>
              </template>
              <!-- <template v-slot:item.kwAffected="{ item }">
                <v-text-field v-model="item.kwAffected"></v-text-field>
              </template> -->
              <template v-slot:item.saveButton="{ item }">
                <!-- <v-btn :disabled="sitePause || (item.paused && item.is_paused)" @click="saveComponent(item)">Save</v-btn> -->
                <v-btn v-if="item.paused" @click="unPauseComponent(item)">Unpause</v-btn>
                <v-btn :disabled="sitePause" v-else @click="pauseComponent(item)">Pause</v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :loading=loading
            v-if="this.eventToCall !== null"
            class="font-weight-bold text-xs btn-success bg-gradient-success align-right"
            @click="emitCustomEvent"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import VueToast from "vue-toast-notification";
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
// Import one of the available themes
import "vue-toast-notification/dist/theme-default.css";
import Service from "@/services/Service.js";
Vue.use(VueToast);

export default {
  components: {
    "k-dialog": Dialog,
  },
  props: {
    site_id: Number,
    show_switch: Boolean,
    eventToCall: String,
  },
  data() {
    return {
      loading: false,
      pauseAllDialog: false,
      pauseAllDate: null,
      showPauseModal: false,
      eventPayload: null,
      datePickerColor: "#2196f3",
      selectedOption: null,
      siteCompSchedule: null,
      selectedComps: null,
      inverterCompSchedule: [],
      componentTypes: [
        { label: "Site", value: "site" },
        { label: "Inverter", value: "inverter" },
      ],
      tableHeaders: [
        { text: "Name", value: "name" },
        { text: "DC Size", value: "dcSize" },
        { text: "Paused", value: "paused" },
        { text: "Date", value: "datePopup" },
        { text: "Pause Until Date", value: "dateText" },
        // { text: "kW Affected", value: "kwAffected" },
        { text: "Pause/Unpause", value: "saveButton" }
      ],
      sitePausedFlag: false,
      componentItems: [],
      sites: [], // Placeholder for site data
      inverters: [], // Placeholder for inverter data
    };
  },
  created: function () {
  },
  computed: {
    results: {
      get() {
        return this.componentItems;
      }
    },
    show: {
      get() {
        if (this.show_switch == true) {
          this.logSite();
          this.fetchSiteData();
          this.fetchInverterData();
        }
        return this.show_switch;
      },
      set(value) {
        this.$emit("input", show_switch);
      },
    },
    sitePause: {
      get() {
        return this.sitePausedFlag;
      }
    },
    // inverterPause: {
    //   get(item) {
    //     console.log(item);
    //     let match = this.inverters.find(i => i.id === item.component_id);
    //     if(match) {
    //       return match.is_paused;
    //     } else {
    //       return false;
    //     }
    //   }
    // },
    
    isDisabled1() {
      if(this.sitePausedFlag == true && this.selectedOption == 'Inverter') {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    openDialog() {
      this.showPauseModal = true;
    },
    closeDialog() {
      this.showPauseModal = false;
    },
    emitCustomEvent() {
      // load kw affected data
      let kwAffectedSum = 0;
      for (let item of this.componentItems) {
        if(item.kwAffected !== null && !isNaN(item.kwAffected)) {
            kwAffectedSum += parseFloat(item.kwAffected);
        }

        kwAffectedSum = parseFloat(kwAffectedSum.toFixed(2));

        // kwAffectedSum += parseInt(item.kwAffected);
      }

      // Example: Emitting the custom event with payload
      if(this.eventToCall == 'updateDataFromPauseModal') {
        this.$emit(this.eventToCall, {"kwAffectedSum": kwAffectedSum})
      } else if(this.eventToCall == 'saveCase') {
        this.$emit(this.eventToCall, {"kwAffectedSum": kwAffectedSum});
      } else if(this.eventToCall == 'saveCloseCase') {
        this.$emit(this.eventToCall, {"kwAffectedSum": kwAffectedSum});
      }
      
      this.$emit("close", true);
    },
    logSite() {
      console.log(this.site_id);
    },
    toggleDialog() {
      // clear out data on close
      this.eventPayload = null;
      this.componentItems = [];
      this.selectedOption = null;
      this.siteCompSchedule = null;
      this.inverterCompSchedule = [];
      this.$emit("close", true);
    },   
    unPauseComponent(item) {
      // Save the data record to the component_schedule api
      
      // we only care if the item.paused is false and there's a record on the comp schedule, if not then ignore
      if (this.selectedOption === "site") {
        // check if inverters are paused here... we don't allow this
        if(this.inverterCompSchedule.length > 0) {
          Vue.$toast.error(`Need to remove inverter schedules first.`, {
                position: "top-right",
              });
        } else if(this.siteCompSchedule) {
          //const match = this.siteCompSchedule.find(x => i.component_id === item.id);
          // if the item is paused and we have a schedule, then we can attempt the removal, otherwise ignore
          // if(!item.paused) {
            // remove the schedule and also flip is_paused flag on site
            let params = {
              isPaused: false
            }
            this.loading = true;
            Service.updateSite(item.id, params)
            .then((response) => {
              console.log(response.data);
              return Service.deleteCompSchedule(item.scheduleID);
            })
            .then((response) => {
              console.log(response.data);
              Vue.$toast.success(`Site unpaused and schedule removed`, {
                position: "top-right",
              });
              this.fetchSiteData();
              this.fetchInverterData();
              this.loading = false;
            })
            .catch((error) => {
              console.log(error);
              this.loading = false;
            });
          // }
        } 
      } else if (this.selectedOption === "inverter") {
        const match = this.inverterCompSchedule.find(x => x.component_id === item.id);
        if(match && !this.sitePause) {
          // remove schedule and also flip is_paused flag on inverter
          let params = {
            isPaused: false
          }
          Service.updateInverterPause(this.site_id, item.id, params)
          .then((response) => {
            console.log(response.data);  
            return Service.deleteCompSchedule(item.scheduleID);
          })
          .then((response) => {
            console.log(response.data);
            Vue.$toast.success(`Inverter unpaused and schedule removed`, {
              position: "top-right",
            });
            this.fetchInverterData();
            this.fetchSiteData();
          })
          .catch((error) => {
            console.log(error);
          });
        } else {
          Vue.$toast.error(`Please unpause the site first`, {
            position: "top-right",
          });
          return;
        }
      }

      // Perform any desired actions when the "Paused" switch is toggled
      console.log("Paused switch toggled for item:", item);
    },
    pauseAllComponents() {
      console.log(this.selectedComps);
      if(this.selectedComps == null) {
        Vue.$toast.error(`Please select a component`, {
            position: "top-right",
        });
        this.pauseAllDialog = false;
        return;
      } else if (this.pauseAllDate == null) {
        Vue.$toast.error(`Missing date`, {
            position: "top-right",
        });
        return;
      } else {
        this.selectedComps.forEach(inverter => {
            let payload = {
              "component_schedule": {
                  "component_id": inverter.id,
                  "component_type_id": 2,
                  "activate_date": this.pauseAllDate,
                  "kw_affected": inverter.kwAffected
              }
            };
            // upsert componentschedule record and also flip pause flag on inverter
            this.loading = true;
            Service.upsertCompSchedule(payload)
              .then((response) => {
                console.log(response.data);
                let resp_data = response.data;
                Vue.$toast.success(`Pause schedule created`, {
                  position: "top-right",
                });
                let site = this.sites[0];
                return Service.updateInverterPause(site.id, resp_data.component_id, { "isPaused": true});
                // this.sites = [response.data];
                // let site = this.sites[0];
                // this.sitePausedFlag = site.isPaused;
              })
              .then((response) => {
                console.log(response.data);
                // refresh site
                this.fetchInverterData();
                this.fetchSiteData();
                // reload grid data
                // this.createInverterComponentItems();
                this.loading = false;
              })
            .catch((error) => {
              console.error(error);
              this.loading = false;
            });
        });
        this.pauseAllDialog = false;
      }
    },
    pauseComponent(item) {
      // need to verify they've checked to pause the component
      // if(item.paused) {
      //   // check if date exists
        if(item.datePopup) {
          // site logic
          if (this.selectedOption === "site") {
            // create payload
            let payload = {
              "component_schedule": {
                  "component_id": item.id,
                  "component_type_id": 1,
                  "activate_date": item.datePopup,
                  "kw_affected": item.kwAffected
              }
            };
            // upsert componentschedule record and also flip pause flag on site
            this.loading = true;
            Service.upsertCompSchedule(payload)
              .then((response) => {
                console.log(response.data);
                let resp_data = response.data;
                Vue.$toast.success(`Pause schedule created`, {
                  position: "top-right",
                });
                return Service.updateSite(resp_data.component_id, { "isPaused": true});
                // this.sites = [response.data];
                // let site = this.sites[0];
                // this.sitePausedFlag = site.isPaused;
              })
              .then((response) => {
                console.log(response.data);
                // refresh site
                this.fetchSiteData();
                this.fetchInverterData();
                this.loading = false;
              })
            .catch((error) => {
              this.loading = false;
              console.error(error);
            });
          } 
          // ** inverter logic **
          else {
            // create payload
            let payload = {
              "component_schedule": {
                  "component_id": item.id,
                  "component_type_id": 2,
                  "activate_date": item.datePopup,
                  "kw_affected": item.kwAffected
              }
            };
            // upsert componentschedule record and also flip pause flag on inverter
            this.loading = true;
            Service.upsertCompSchedule(payload)
              .then((response) => {
                console.log(response.data);
                let resp_data = response.data;
                Vue.$toast.success(`Pause schedule created`, {
                  position: "top-right",
                });
                let site = this.sites[0];
                return Service.updateInverterPause(site.id, resp_data.component_id, { "isPaused": true});
                // this.sites = [response.data];
                // let site = this.sites[0];
                // this.sitePausedFlag = site.isPaused;
              })
              .then((response) => {
                console.log(response.data);
                // refresh site
                this.fetchInverterData();
                this.fetchSiteData();
                // reload grid data
                // this.createInverterComponentItems();
                this.loading = false;
              })
            .catch((error) => {
              console.error(error);
              this.loading = false;
            });
          }
        } else {
          Vue.$toast.error(`Missing date`, {
            position: "top-right",
          });
        }
      // } else {
      //   Vue.$toast.error(`Must select to pause component`, {
      //       position: "top-right",
      //     });
      // }
      
      console.log(item, 'saved')
    },
    createSiteComponentItems() {
      // TODO: here the site flag (is_paused will override the component_schedule record)
      let activateDate = null;
      let scheduleID = null;
      let kw = null;
      // if we have a site component schedule record, let's pull the date for visual purposes
      if(this.siteCompSchedule) {
        activateDate = this.siteCompSchedule.activate_date;
        scheduleID = this.siteCompSchedule.id;
        kw = this.siteCompSchedule.kw_affected;
      }
      const scheduled = activateDate === null || activateDate === undefined ? false : true;
      let site = this.sites[0];
      let data = [
        { 
          name: site.name, 
          id: site.id, 
          scheduleID: scheduleID,
          paused: site.isPaused,
          dcSize: site.dcSize,
          kwAffected: kw, 
          datePopup: activateDate, 
          type: 1, 
          is_scheduled: scheduled
        },
        // Add more site data if needed
      ];
      return data;
    },
    createInverterComponentItems() {
      let data = [];
      // console.log(this.inverters)
      this.inverters.map(inv => {
        // check to see if we have a activate date
        let activateDate = null;
        let scheduleID = null;
        let kw = null;
        let match = this.inverterCompSchedule.find(i => i.component_id === inv.id);
        if(match) {
          activateDate = match.activate_date;
          scheduleID = match.id;
          kw = match.kw_affected;
        }
        // TODO: add a scheduled flag here; true/false.  This will help later determine if the paused switch is enabled.
        // if we have a schedule, we allow the switch to move to false, otherwise we'll disable the switch
        const scheduled = match === null || match === undefined ? false : true;

        const obj = {
          name: inv.name, 
          id: inv.id,
          scheduleID: scheduleID,
          type: 2,
          paused: inv.is_paused, 
          is_paused: inv.is_paused,
          dcSize: inv.dc_size_kw,
          kwAffected: kw, 
          datePopup: activateDate,
          is_scheduled: scheduled
        }
        data.push(obj);
      })
      return data;
    },
    loadComponentItems() {
      if (this.selectedOption === "site") {
        // Load site data
        // TODO: here the site flag (is_paused will override the component_schedule record)
        this.componentItems = this.createSiteComponentItems();

      } else if (this.selectedOption === "inverter") {
        // Load inverter data
        this.componentItems = 
          // { name: "Inverter 1", paused: true, datePopup: null },
          // { name: "Inverter 2", paused: false, datePopup: null },
          this.createInverterComponentItems();
          // Add more inverter data if needed
        
      } else {
        this.componentItems = []; // Reset component items if no option is selected
      }
    },
    fetchSiteData() {
      this.siteCompSchedule = null;
      this.sitePausedFlag = false;
      // Make an API call to fetch site data
      this.loading = true;
      Service.getSite(this.site_id)
        .then((response) => {
          this.sites = [response.data];
          let site = this.sites[0];
          this.sitePausedFlag = site.isPaused;
          console.log(`sitePausedFlag = ${site.isPaused}`);
          // load componentschedule data
          return Service.getCompScheduleByType(site.id, 1)
        })
        .then((response) => {
          this.siteCompSchedule = response.data;
          this.loadComponentItems();
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.error(error);
        });
    },
    
    fetchInverterData() {
      // Make an API call to fetch inverter data
      this.loading = true;
      Service.getInvertersGridAllMaster(this.site_id)
        .then((response) => {
          this.inverters = response.data;
          // load componentschedule data
          // return Service.getCompScheduleByType(site.id, 1)
          const serviceCalls = [];

          // Loop through your array and create promises for each service call
          for (const item of this.inverters) {
            const promise = Service.getCompScheduleByType(item.id, 2)
            serviceCalls.push(promise);
          }
          return Promise.all(serviceCalls);
        })
        .then((response) => {
          console.log(response.data);
          this.inverterCompSchedule = [];
          // save component schedule for our inverters
          for(const r of response) {
            let d = r.data;
            if(d) {
              this.inverterCompSchedule.push(d);
            }
          }
          // reload grid
          this.loadComponentItems();
          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
        });
    },
    updateDateText(item) {
      if (item.datePopup) {
        const selectedDate = new Date(item.datePopup);
        const formattedDate =
          selectedDate.getUTCFullYear() +
          "-" +
          (selectedDate.getUTCMonth() + 1) +
          "-" +
          selectedDate.getUTCDate();
        item.dateText = formattedDate;

        // Handle date change here
        console.log('date change');
        // TODO: make api call here to update comp schedule record

      } else {
        item.dateText = "";
      }
    },
  },
};
</script>
<style>
  #pauseX_btn {
    float: right;
    width: 48px;
    height: 48px;
  }
  .custom-date-picker .v-date-picker-table td.is-selected:not(.is-range):not(.disabled) {
    background-color: #2196f3 !important;
  }
</style>