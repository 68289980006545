<template>
  <v-dialog v-model="orderDialog" max-width="700px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn 
        :disabled="user.claims.user_role == 'customer_view'" 
        style="margin-left:-1%;" 
        color="primary" 
        dark class="mb-2" 
        v-bind="attrs" 
        v-on="on"
      >
        Work Order
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Work Order</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="accessNotes"
                label="Equipment access notes"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-for="(e, index) in equipment" :key="index">
            <v-col cols="6" sm="6" md="6">
              <v-text-field
                v-model="e.name"
                label="Equipment name"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="6" md="6">
              <v-text-field
                v-model="e.inverter_monitoring_id"
                label="Serial number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-for="(info, index) in infoFields" :key="index">
            <v-col cols="6" sm="6" md="6">
              <v-text-field
                v-model="info.equipmentName"
                label="Equipment name"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="6" md="6">
              <v-text-field
                v-model="info.serialNumber"
                label="Serial number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-btn 
            color="black" outlined
            @click="addRow(index)"
          >
            Add New Row
          </v-btn>
          <v-btn 
          color="black" outlined
          class="ml-2"
          show="infoFields.length > 1" 
          @click="removeRow(index)">
            Remove Row
          </v-btn>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn 
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-default bg-gradient-danger" 
          @click="close"
        > Cancel </v-btn>
        <v-btn 
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-success bg-gradient-success" 
          @click="generatePdf">
          Generate Work Order PDF
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";

import Service from "@/services/Service.js";
Vue.use(VueToast);

export default {
  props: ["case_id", "subject", "date", "site_name", "equipment"],
  computed: {
    user: function() {
      return this.$store.getters.currentUser;
    },
  },
  data: function() {
    return {
      orderDialog: false,
      accessNotes: null,
      index: 0,
      infoFields: [
        {
          equipmentName: "",
          serialNumber: "",
        },
      ],
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    case_id: function(newVal, oldVal) { // watch it
      console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      console.log('reset values...');
      // reset to all sites on tab change
      this.initialize();
    },
  },

  methods: {
    initialize() {
      this.accessNotes = "";
      this.infoFields = [
          {
            equipmentName: "",
            serialNumber: "",
          },
        ];
    },
    openWindow: function(link) {
      window.open(link, "_blank");
    },
    close() {
      this.orderDialog = false;
    },
    handlePDFDownload(response, sitename) {
      const blob = new Blob([response.data], { type: 'application/pdf' })
      const link = document.createElement('a')
      let filename = sitename + " - Work Order.pdf"
      link.href = URL.createObjectURL(blob)
      link.download = filename
      link.click()
      URL.revokeObjectURL(link.href)
    },
    generatePdf() {
      let c = this.equipment.map((q) => {
        return {equipmentName: q.name, serialNumber: q.inverter_monitoring_id}
      })
      let d = c.concat(this.infoFields)
      var params = {
        case_id: this.case_id,
        access_notes: this.accessNotes,
        info_fields: d,
      };

      Service.downloadCaseWorkOrderPDF(this.case_id, params)
        .then((response) => {
          this.handlePDFDownload(response, this.site_name);
        })
        .catch((error) => {
          console.log("Error getting data:", error);
        });
    },
    addRow() {
      this.infoFields.push({
        equipmentName: "",
        serialNumber: "",
      });
    },
    removeRow(index) {
      this.infoFields.pop();
    },
  },
};
</script>
